import React, { useEffect, useRef, useState } from "react";
import "./styles/App.css";
import NavBar from "./components/NavBar";
import Desktop from "./components/Desktop";
import { FooterDataProvider } from "./components/FooterDataContext";
import Header from "./components/Header";
import LaodScreen from "./components/LoadScreen";
import { DisplayPriorityProvider } from "./components/DisplayPriorityContext";
import windXPStart from "./assets/start.mp3";

function App() {
  const [load, setLoad] = useState<boolean>(true);
  const [user, setUser] = useState<string>("Yassine Khayri");
  const startRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!load) {
      startRef.current.play();
    }
  }, [load]);

  const myUser = (userName: string) => {
    setUser(!userName.length ? "Yassine Khayri" : userName);
  };

  return (
    <DisplayPriorityProvider>
      <FooterDataProvider>
        <div className="App">
          <audio ref={startRef} src={windXPStart} muted={false} />
          {load ? (
            <LaodScreen users={myUser} signIn={setLoad}></LaodScreen>
          ) : (
            <>
              <Header user={user}></Header>
              <Desktop></Desktop>
              <NavBar users={user} load={setLoad}></NavBar>
            </>
          )}
        </div>
      </FooterDataProvider>
    </DisplayPriorityProvider>
  );
}

export default App;
