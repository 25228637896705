import React, { useRef } from "react";
import IconLabel from "./IconLabel";
import windXPOff from "../assets/off.mp3";

const StartMenu = ({
  cont,
  user,
  load,
}: {
  cont: any;
  user: string;
  load: (el: boolean) => void;
}) => {
  const offRef = useRef<HTMLAudioElement>(null);

  const includes: { [data: string]: string } = {
    My_Resume: "PDF",
    Contact: "Contact",
    Calculator: "Calculator",
  };

  const logOut = () => {
    load(true);
  };

  const turnOff = () => {
    offRef.current.play();
    setTimeout(() => {
      window.open("about:blank", "_self");
      window.close();
    }, 2000);
  };

  return (
    <section ref={cont} className="startMenu">
      <audio ref={offRef} src={windXPOff} muted={false} />
      <h4 className="currUser">{user}</h4>
      <div className="menuBody">
        {Object.keys(includes).map((lab, index) => {
          return (
            <IconLabel key={index} item={lab} start={includes[lab]}></IconLabel>
          );
        })}
      </div>
      <div className="power">
        <div className="machineCont logout" onClick={logOut}></div>
        <div
          className="machineCont powerOff"
          onClick={() => {
            turnOff();
          }}
        ></div>
      </div>
    </section>
  );
};

export default StartMenu;
