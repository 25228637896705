import React from "react";
import Library from "./Library";

interface Desc {
  title: string;
  desc: string[][];
  techno: string[];
  gal?: string[];
}

interface Data {
  type: string;
  img?: string;
  alt?: string;
  links?: string[];
  data?: Desc;
  title?: string;
  role?: string[];
  short?: string[];
  child?: SocialLinksProps;
}

interface Child extends Data {
  parent: string;
}

interface SocialLinksProps {
  [par: string]: Data | Child;
}

const SocialLinks: React.FC<SocialLinksProps> = ({ data }: { data: any }) => {
  return (
    <div className="social_links">
      {data.links.map((link: string) => {
        let tmp: string = link.split(".")[1];
        tmp = tmp.charAt(0).toUpperCase() + tmp.slice(1);
        return (
          <a key={link} href={link} target="_blank" rel="noreferrer">
            <img
              className="social"
              src={Library[tmp] ? Library[tmp].src : Library.site.src}
              alt={Library[tmp] ? Library[tmp].alt : Library.site.alt}
            />
          </a>
        );
      })}
    </div>
  );
};

export default SocialLinks;
