import React, { createContext, useContext, useEffect, useState } from "react";

const FooterDataContext = createContext(undefined);

interface MyLabels {
  [name: string]: {
    path: string;
    parent: string;
  };
}

export const FooterDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [footerLabels, setFooterLabels] = useState<MyLabels>({});
  const [easyLabels, setEasyLabels] = useState<string[]>([]);

  useEffect(() => {
    let tmp: string[] = [];
    for (let i in footerLabels) {
      if (
        !footerLabels[i].parent.length ||
        (footerLabels[i].path.length && footerLabels[footerLabels[i].parent])
      )
        tmp.push(i);
      if (
        footerLabels[i].parent.length &&
        !footerLabels[footerLabels[i].parent]
      )
        delete footerLabels[i];
    }
    setEasyLabels(tmp);
  }, [footerLabels]);

  const refreshFooter = (obj: MyLabels) => {
    setFooterLabels(obj);
  };

  return (
    <FooterDataContext.Provider
      value={{ footerLabels, refreshFooter, easyLabels }}
    >
      {children}
    </FooterDataContext.Provider>
  );
};

export const useFooterData = () => {
  return useContext(FooterDataContext);
};
