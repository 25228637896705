import React from "react";
import Library from "./Library";
import SocialLinks from "./SocialLinks";

interface Desc {
  title: string;
  desc: string[][];
  techno: string[];
  gal?: string[];
}

interface Data {
  type: string;
  img?: string;
  alt?: string;
  links?: string[];
  data?: Desc;
  title?: string;
  role?: string[];
  short?: string[];
  child?: PostInfoProps;
}

interface Child extends Data {
  parent: string;
}

interface PostInfoProps {
  [par: string]: Data | Child;
}

const PostInfo: React.FC<PostInfoProps> = ({ data }) => {
  return (
    <section className={"postInfo" + (data.img ? "" : " parentPost")}>
      <img
        className={data.img ? "thumb" : "parentF"}
        src={data.img ? data.img : Library[data.type].src}
        alt={data.img ? data.alt : Library[data.type].alt}
      />
      {data.role.map((p: string) => {
        return (
          <h4 className="post_title" key={p}>
            {p}
          </h4>
        );
      })}
      <div className="paragraphs">
        {data.short.map((parag: string, index: number) => {
          return (
            <p className="shortPar" key={index}>
              {parag}
            </p>
          );
        })}
      </div>
      {data.type !== "Folder" && data.links ? (
        <SocialLinks data={data}></SocialLinks>
      ) : (
        <></>
      )}
    </section>
  );
};

export default PostInfo;
