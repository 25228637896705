import React from "react";

interface WindContProps {
  title: string;
  role: string;
  func?: (e?: any) => void;
}

const WindCont: React.FC<WindContProps> = ({ title, role, func }) => {
  return (
    <label
      onClick={func}
      htmlFor={title.replace(/ /g, "_") + (role === "size" ? "_size" : "")}
      className={`controlButton ${role === "close" ? "red" : role}`}
    ></label>
  );
};

export default WindCont;
