import React, { useEffect, useState } from "react";
import "../styles/header.css";

interface HeaderProp {
  user: string;
}

const Header: React.FC<HeaderProp> = ({ user }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <header>
      <h2>Welcome {user}!</h2>
      <div className="dateTime">
        <small>{time.toLocaleTimeString()}</small>
        <small>{time.toLocaleDateString()}</small>
      </div>
    </header>
  );
};

export default Header;
