import React, { createContext, useContext, useState } from "react";

interface DisplayPriority {
  priority: string;
  refreshPriority: (first: string) => void;
}

const DisplayPriorityContext = createContext<DisplayPriority | undefined>(
  undefined
);

export const DisplayPriorityProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [priority, setPriority] = useState<string>("");

  const refreshPriority = (first: string) => {
    setPriority(first);
  };

  return (
    <DisplayPriorityContext.Provider value={{ priority, refreshPriority }}>
      {children}
    </DisplayPriorityContext.Provider>
  );
};

export const useDisplayPriority = (): DisplayPriority => {
  return useContext(DisplayPriorityContext);
};
