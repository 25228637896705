import React from "react";
import PostInfo from "./PostInfo";
import PostDetails from "./PostDetails";
import Contact from "./Contact";

interface WindBodyProps {
  dataType: string;
  data: any;
}

const WindBody: React.FC<WindBodyProps> = ({ dataType, data }) => {
  return (
    <section className={"windBody " + dataType}>
      {dataType === "embed" ? (
        <object data={data.links[0]} type="application/pdf" className="embed">
          <iframe title=" " src="about:blank"></iframe>
          <p>Download and open the file.</p>
          <a
            href={data.links[0]}
            download={data.links[0].split(".")[0].split("/").slice(-1)}
          >
            {` ${data.links[0].split(".")[0].split("/").slice(-1)}.pdf`}
          </a>
        </object>
      ) : (
        <>
          <PostInfo data={data}></PostInfo>
          {data.type === "Contact" ? (
            <Contact></Contact>
          ) : (
            <PostDetails
              type={data.type}
              data={data.type === "Folder" ? data.child : data.data}
            ></PostDetails>
          )}
        </>
      )}
    </section>
  );
};

export default WindBody;
