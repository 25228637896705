import React, { MouseEvent } from "react";
import WindCont from "./WindCont";
import { useFooterData } from "./FooterDataContext";
import Library from "./Library";

interface WindowNavProps {
  title: string;
  fullWind?: () => void;
  hideWind?: () => void;
}

const WindowNav: React.FC<WindowNavProps> = ({ title, hideWind, fullWind }) => {
  const { footerLabels, refreshFooter } = useFooterData();

  const closeWind = (e: MouseEvent<HTMLLabelElement>) => {
    const labelTar = e.target as HTMLLabelElement;
    delete footerLabels[labelTar.htmlFor];
    refreshFooter((prevLabels: any) => ({ ...prevLabels }));
  };

  const buttons: { [tit: string]: (e?: string) => void } = {
    mini: hideWind,
    size: fullWind,
    close: closeWind,
  };

  return (
    <div className="controls">
      <div className="windTitle">
        <img
          src={Library[footerLabels[title.replace(/ /g, "_")]?.path]?.src}
          alt={title}
        />
        <h3>{title}</h3>
      </div>
      <div className="controlCont">
        {Object.keys(buttons).map((but) => {
          return (
            <WindCont
              key={but}
              title={title}
              role={but}
              func={buttons[but]}
            ></WindCont>
          );
        })}
      </div>
    </div>
  );
};

export default WindowNav;
