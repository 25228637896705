import React, { useEffect, useState } from "react";

interface CarouselProps {
  media: string[];
  alt: string;
}

const Carousel: React.FC<CarouselProps> = ({ media, alt }) => {
  const [move, setMove] = useState<number>(0);

  useEffect(() => {
    const resise_cards = (): void => {
      setMove(0);
    };
    window.addEventListener("resize", resise_cards);
    return () => window.removeEventListener("resize", resise_cards);
  });

  const move_slider = (direction: number) => {
    if (move + direction > 0) setMove(-1 * (media.length - 1));
    else if (move + direction < (media.length - 1) * -1) setMove(0);
    else setMove(move + direction);
  };

  return (
    <section className={"Carousel anim"}>
      {media.length > 1 ? (
        <>
          <label
            className="lab_left"
            onClick={() => {
              move_slider(1);
            }}
          ></label>
          <label
            className="lab_right"
            onClick={() => {
              move_slider(-1);
            }}
          ></label>
        </>
      ) : (
        <></>
      )}
      <div
        className="slider"
        style={{
          left: `calc(${move} * (100% + 1%))`,
        }}
      >
        {media.map((elm: string, index: number) => {
          return (
            <div key={index} className={"slide"}>
              {elm.slice(-3) !== "mp4" ? (
                <img src={elm} alt={alt} />
              ) : (
                <video src={elm} autoPlay loop muted></video>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Carousel;
