import React from "react";
import { useFooterData } from "./FooterDataContext";
import Library from "./Library";

interface IconLabelProps {
  item: string;
  start: string;
}

const IconLabel: React.FC<IconLabelProps> = ({ item, start }) => {
  const { footerLabels } = useFooterData();

  return (
    <label
      htmlFor={item}
      className={"footerLabel" + (start.length ? " startMenuButton" : "")}
    >
      <img
        src={
          start.length
            ? Library[start].src
            : Library[footerLabels[item]?.path]?.src
        }
        alt={item}
      />
      <p>{item.replace(/_/g, " ")}</p>
    </label>
  );
};

export default IconLabel;
