import React from "react";
import Library from "./Library";
import WindIcon from "./WindIcon";
import Dataset from "./Dataset";
import Carousel from "./Carousel";

interface Desc {
  title: string;
  desc: string[][];
  techno: string[];
  gal?: string[];
  parent?: string;
}

interface PostDetailsProps {
  type: string;
  data: Desc;
}

interface Images {
  [par: string]: {};
}

const PostDetails: React.FC<PostDetailsProps> = ({ type, data }) => {
  const exclude: Images = {
    Folder: {},
    PDF: {},
    Sys: {},
    Github: {},
    Linkedin: {},
    Project: {},
    Calculator: {},
    Contact: {},
    Logo: {},
    site: {},
  };

  const arrTech: string[] =
    data?.techno && data?.techno[0] === "all"
      ? Object.keys(Library)
      : data?.techno;

  return (
    <section
      className={"postDetails" + (type !== "Folder" ? "" : " postIcons")}
    >
      {type !== "Folder" ? (
        <>
          {data.gal && data.gal.length ? (
            <Carousel media={data.gal} alt={data.title}></Carousel>
          ) : (
            <></>
          )}
          <h3 className="detailTitle">{data.title}</h3>
          {data.desc.map((element: string[], index: number) => {
            if (element[0] !== "a")
              return (
                <p className={"detailP " + element[0]} key={index}>
                  {element[1]}
                </p>
              );
            return (
              <a
                className="anchor"
                key={index}
                target="_blank"
                rel="noreferrer"
                href={element[1]}
              >
                {element[2]}
              </a>
            );
          })}
          <h3 className="detailTitle">Technologies</h3>
          <div className="skills">
            {arrTech?.map((item, index) => {
              if (exclude[item] === undefined)
                return (
                  <img
                    key={index}
                    src={Library[item].src}
                    alt={Library[item].alt}
                    className="tech"
                  />
                );
              return "";
            })}
          </div>
        </>
      ) : (
        <div className="collection">
          {Object.keys(data).map((d, index: number) => {
            return (
              <WindIcon
                supp="nestedIcon"
                key={index}
                iconName={d}
                path={Dataset[data[d].parent].child[d].type}
                parent={data[d].parent}
              ></WindIcon>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default PostDetails;
