import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/contact.css";

type FormEvent = React.FormEvent<HTMLFormElement>;

const Contact: React.FC = () => {
  const [send, setSend] = useState<string>("Send");

  const sendMail = (e: FormEvent) => {
    e.preventDefault();
    setSend("Sending...");
    emailjs
      .sendForm(
        "service_pnpvvtd",
        "template_ea1peem",
        e.target as HTMLFormElement,
        "ZfXczR7q2V1nvhJTA"
      )
      .then(
        () => {
          setSend("✅︎ Sent!");
          setTimeout(() => {
            setSend("Send");
          }, 1500);
        },
        (error: any) => {
          alert(error);
        }
      );
  };

  return (
    <section id="Contact" className="postDetails">
      <form onSubmit={sendMail}>
        <label htmlFor="name">Name:</label>
        <input
          type="name"
          placeholder="Your name"
          id="name"
          name="from_name"
          required
        />
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          placeholder="Your email"
          id="email"
          name="email_from"
          required
        />
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          placeholder="Your message"
          required
        ></textarea>
        <input
          value={send}
          type="submit"
          className={"button " + (send === "✅︎ Sent!" ? "done" : "")}
        />
      </form>
    </section>
  );
};

export default Contact;
