interface Images {
  [par: string]: {
    src: string;
    alt: string;
  };
}

const Library: Images = {
  Logo: {
    src: require("../assets/logo.svg").default,
    alt: "Yassine Khayri",
  },
  Folder: {
    src: require("../assets/icons/dir.png"),
    alt: "System folder",
  },
  Project: {
    src: require("../assets/sys/pj1.png"),
    alt: "Project",
  },
  Contact: {
    src: require("../assets/sys/contact.png"),
    alt: "Contact",
  },
  Calculator: {
    src: require("../assets/sys/calc.png"),
    alt: "Calculator",
  },
  PDF: {
    src: require("../assets/icons/pdf.png"),
    alt: "PDF",
  },
  Sys: {
    src: require("../assets/icons/sys.png"),
    alt: "System icon",
  },
  Github: {
    src: require("../assets/socials/github.png"),
    alt: "Yassine Khayri - Github",
  },
  Linkedin: {
    src: require("../assets/socials/linkedin.png"),
    alt: "Yassine Khayri - LinkedIn",
  },
  site: {
    src: require("../assets/socials/site.svg").default,
    alt: "Yassine Khayri - site",
  },
  Html5: { src: require("../assets/techno/html5.svg").default, alt: "HTML5" },
  Css3: { src: require("../assets/techno/css3.svg").default, alt: "CSS3" },
  Sass: { src: require("../assets/techno/sass.svg").default, alt: "SASS" },
  Bootstrap: {
    src: require("../assets/techno/bootstrap.svg").default,
    alt: "Bootstrap",
  },
  Javascript: {
    src: require("../assets/techno/javascript.svg").default,
    alt: "JavaScript",
  },
  Jquery: {
    src: require("../assets/techno/jquery.svg").default,
    alt: "JQuery",
  },
  TypeScript: {
    src: require("../assets/techno/typescript.png"),
    alt: "TypeScript",
  },
  ReactJs: {
    src: require("../assets/techno/react.svg").default,
    alt: "ReactJS",
  },
  Materialui: {
    src: require("../assets/techno/materialui.svg").default,
    alt: "MaterialUI",
  },
  Wordpress: {
    src: require("../assets/techno/wordpress.svg").default,
    alt: "WordPress",
  },
  Elementor: {
    src: require("../assets/techno/Elementor.svg").default,
    alt: "Elementor",
  },
  Git: { src: require("../assets/techno/git.svg").default, alt: "Git" },
  Figma: { src: require("../assets/techno/figma.svg").default, alt: "Figma" },
  Ill: {
    src: require("../assets/techno/Illustrator.svg").default,
    alt: "Illustrator",
  },
  Photo: {
    src: require("../assets/techno/photoshop.svg").default,
    alt: "Photoshop",
  },
  Bash: { src: require("../assets/techno/bash.svg").default, alt: "Bash" },
  Mysql: { src: require("../assets/techno/mysql.svg").default, alt: "MySQL" },
  Php: { src: require("../assets/techno/php.svg").default, alt: "PHP" },
  C: { src: require("../assets/techno/c.svg").default, alt: "C" },
  Cplusplus: {
    src: require("../assets/techno/cplusplus.svg").default,
    alt: "C++",
  },
};

export default Library;
