import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFooterData } from "./FooterDataContext";
import Library from "./Library";
import "../styles/footer.css";
import StartMenu from "./StartMenu";
import IconLabel from "./IconLabel";

interface NavBarProps {
  users: string;
  load: (el: boolean) => void;
}

const NavBar: React.FC<NavBarProps> = ({ users, load }) => {
  const { easyLabels } = useFooterData();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const test: string[] = [...easyLabels];
  const startRef = useRef<HTMLDivElement | null>(null);

  const outClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isOpen && startRef.current) {
        if (!startRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", outClick);

    return () => {
      document.removeEventListener("mousedown", outClick);
    };
  }, [outClick]);

  return (
    <footer>
      <input
        onChange={() => {}}
        className="unseen"
        type="checkbox"
        id="startM"
        checked={isOpen}
      />
      <label
        onClick={() => setIsOpen(!isOpen)}
        htmlFor="startM"
        className="footerLabel start"
      >
        <img src={Library.Logo.src} alt={Library.Logo.alt} /> Start
      </label>
      <StartMenu cont={startRef} load={load} user={users}></StartMenu>
      <div className="current_open">
        {test.length
          ? test.map((l: string) => {
              return <IconLabel key={l} item={l} start=""></IconLabel>;
            })
          : ""}
      </div>
    </footer>
  );
};

export default NavBar;
