import React, { useState } from "react";
import WindowNav from "./WindowNav";
import WindBody from "./WindBody";
import { useFooterData } from "./FooterDataContext";
import Dataset from "./Dataset";
import Calculator from "./Calculator";

interface WindProps {
  prior: string;
  supp: string;
  title: string;
  parent: string;
}

const Wind: React.FC<WindProps> = ({ prior, supp, title, parent }) => {
  const [large, setLarge] = useState<boolean>(false);
  const [mini, setMini] = useState<boolean>(false);
  const { footerLabels } = useFooterData();

  const resize = (): void => {
    setLarge(!large);
  };

  const minimize = (): void => {
    setMini(!mini);
  };

  const rep: string = title.replace(/ /g, "_");

  return (
    <section
      id={rep}
      className={`window ${large ? "fullscreen" : "resize"} ${supp} ${
        prior === rep || (Dataset[rep]?.child && Dataset[rep]?.child[prior])
          ? "forward"
          : ""
      }`}
    >
      <WindowNav
        title={title}
        fullWind={resize}
        hideWind={minimize}
      ></WindowNav>
      {title === "Calculator" ? (
        <Calculator></Calculator>
      ) : (
        <WindBody
          dataType={footerLabels[rep]?.path === "PDF" ? "embed" : "post"}
          data={Dataset[rep] ? Dataset[rep] : Dataset[parent].child[rep]}
        ></WindBody>
      )}
    </section>
  );
};

export default Wind;
