import React from "react";
import "../styles/load.css";
import Yassine from "../assets/prj/YassineKhayri.png";
import Arrow from "../assets/sys/enter.svg";

interface LaodScreenProps {
  users: (username: string) => void;
  signIn: (isLoggedIn: boolean) => void;
}

const LaodScreen: React.FC<LaodScreenProps> = ({ users, signIn }) => {
  const sign = (): void => {
    signIn(false);
  };

  const readVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    users(e.target.value);
  };

  return (
    <section className="loadScreen">
      <div className="loadBody">
        <img className="owner" src={Yassine} alt="Yassine Khayri" />
        <h1>
          Yassine Khayri
          <br />
          Front-end developer | Graphic Designer
        </h1>
        <div className="userName">
          <input onBlur={readVal} type="text" placeholder="Your name" />
          <button onClick={sign}>
            <img src={Arrow} alt="enter" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default LaodScreen;
