import React, { useState } from "react";
import "../styles/calculator.css";

interface CalculatorState {
  output: string;
  a: number;
  op: string;
  m: number;
}

const Calculator: React.FC = () => {
  const [calcule, setCalcule] = useState<CalculatorState>({
    output: "",
    a: 0,
    op: "",
    m: 0,
  });

  const myInput = (): void => {};

  const red: string[] = [
    "Backspace",
    "CE",
    "C",
    "MC",
    "MR",
    "MS",
    "M+",
    "/",
    "-",
    "*",
    "+",
    "=",
  ];

  const buttons: string[] = [
    "Backspace",
    "CE",
    "C",
    "MC",
    "7",
    "8",
    "9",
    "/",
    "sqrt",
    "MR",
    "4",
    "5",
    "6",
    "*",
    "%",
    "MS",
    "1",
    "2",
    "3",
    "-",
    "1/x",
    "M+",
    "0",
    "+/-",
    ".",
    "+",
    "=",
  ];

  const handleButton = (inp: string) => {
    const operations: { [str: string]: (a: number, b: number) => number } = {
      "+": (a: number, b: number) => {
        return a + b;
      },
      "-": (a: number, b: number) => {
        return a - b;
      },
      "/": (a: number, b: number) => {
        return a / b;
      },
      "*": (a: number, b: number) => {
        return a * b;
      },
    };

    if (calcule.output === "Infinity") {
      setCalcule((prevCalc) => ({ ...prevCalc, output: "" }));
    }

    if (inp === "C" || inp === "CE") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        a: prevCalcule.output.length ? +prevCalcule.output : prevCalcule.a,
        output: "",
      }));
    }
    if (inp === "C") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        a: 0,
        op: "",
      }));
    }
    if (operations[inp]) {
      if (calcule.output === ".")
        setCalcule((prevCalcule) => ({ ...prevCalcule, output: "0" }));
      if (!calcule.op.length)
        setCalcule((prevCalcule) => ({
          ...prevCalcule,
          output: "",
          op: inp,
          a: prevCalcule.output.length ? +prevCalcule.output : prevCalcule.a,
        }));
      else {
        /**successive operations */
        setCalcule((prevCalcule) => ({
          ...prevCalcule,
          output: "",
          a: prevCalcule.output.length
            ? operations[prevCalcule.op](prevCalcule.a, +prevCalcule.output)
            : prevCalcule.a,
        }));
        setCalcule((prevCalcule) => ({ ...prevCalcule, op: inp }));
      }
    } else if (inp === "=" && calcule.op.length) {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        op: "",
        output: prevCalcule.output.length
          ? operations[prevCalcule.op](
              prevCalcule.a,
              +prevCalcule.output
            ).toString()
          : prevCalcule.a.toString(),
        a: 0,
      }));
    } else if (inp === "M+") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        op: "",
        m: prevCalcule.output.length
          ? prevCalcule.m + +prevCalcule.output
          : prevCalcule.m,
      }));
    } else if (inp === "MR") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        op: "",
        output: prevCalcule.m.toString(),
        m: prevCalcule.m,
      }));
    } else if (inp === "MC") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        m: 0,
      }));
    } else if (inp === "MS") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        m: +prevCalcule.output,
      }));
    } else if (inp === "Backspace") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        output: prevCalcule.output.slice(0, -1),
      }));
    } else if (inp === "+/-") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        output: (+prevCalcule.output * -1).toString(),
      }));
    } else if (inp === "sqrt") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        output: Math.sqrt(+prevCalcule.output).toString(),
      }));
    } else if (inp === "1/x" && +calcule.output) {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        output: (1 / +prevCalcule.output).toString(),
      }));
    } else if (calcule.op.length && inp === "%") {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        output: ((prevCalcule.a * +prevCalcule.output) / 100).toString(),
      }));
    } else if (
      "0123456789".includes(inp) ||
      (inp === "." && !calcule.output.includes("."))
    ) {
      setCalcule((prevCalcule) => ({
        ...prevCalcule,
        output: prevCalcule.output + inp,
      }));
    }
  };

  return (
    <section className="windBody calculator">
      <input
        id="myCalc"
        type="text"
        onChange={myInput}
        value={calcule.output}
        disabled
      />
      <div className="inputGrid">
        {buttons.map((click: string, index: number) => {
          return (
            <button
              onClick={() => {
                handleButton(click);
              }}
              key={index}
              className={"calcB" + (red.includes(click) ? " redText" : "")}
            >
              {click}
            </button>
          );
        })}
      </div>
    </section>
  );
};

export default Calculator;
