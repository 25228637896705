import React, {ChangeEvent} from "react";
import Library from "./Library";
import Wind from "./Wind";
import { useFooterData } from "./FooterDataContext";
import { useDisplayPriority } from "./DisplayPriorityContext";

interface Explorer {
  iconName: string;
  path: string;
  parent: string;
  supp: string;
}

const WindIcon: React.FC<Explorer> = ({ supp, iconName, path, parent }) => {
  const { footerLabels, refreshFooter } = useFooterData();
  const { priority, refreshPriority } = useDisplayPriority();

  const updateFooter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      refreshPriority(e.target.id);
      if (!footerLabels[e.target.id])
        refreshFooter({
          ...footerLabels,
          [e.target.id]: { path: path, parent: parent },
        });
    }
  };

  const rep: string = iconName.replace(/ /g, "_");

  return (
    <div className={"desktopIcon" + (path === "Contact" ? " sender" : "")}>
      <input
        className="unseen"
        type="checkbox"
        id={rep}
        onChange={updateFooter}
      />
      <label
        className={"icon" + (path === "Calculator" ? " unseen" : "")}
        htmlFor={rep}
      >
        <img src={Library[path].src} alt={Library[path].alt} />
        {path === "PDF" || parent !== "1337_Coding_School"
          ? iconName.replace(/_/g, " ")
          : iconName}
      </label>
      {footerLabels[rep] ? (
        <Wind
          prior={priority}
          supp={supp}
          title={iconName}
          parent={parent}
        ></Wind>
      ) : (
        <></>
      )}
    </div>
  );
};

export default WindIcon;
