import React from "react";
import WindIcon from "./WindIcon";
import Dataset from "./Dataset";
import "../styles/desktop.css";

const Desktop: React.FC = () => {
  return (
    <main className="desktop">
      {Object.keys(Dataset).map((item: string) => {
        return (
          <WindIcon
            key={item}
            supp={""}
            parent=""
            iconName={item.replace(/_/g, " ")}
            path={Dataset[item].type}
          />
        );
      })}
    </main>
  );
};

export default Desktop;
